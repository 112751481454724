@import '../../../../node_modules/@chocolate-soup-inc/cs-frontend-components/lib/scss/typography.scss';

.overviewPage {
  padding: 0 32px 32px;
  gap: 16px;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto 1fr;
  grid-template-areas:
    'title title title'
    'overview overview overview'
    'attention attention events';
}

.overviewPageTitle {
  grid-area: title;
}

.overviewPageEmployeesError {
  margin-top: 32px;
}

.overviewPageEmployeesAlert {
  margin-top: 32px;
}

.overviewBoxesContainer {
  grid-area: overview;
  display: flex;
  align-items: stretch;
  gap: 16px;
}


.deliveryOverviewBox {
  flex: 1;
  padding: 0 !important;
}

.deliveryOverviewBoxButton {
  flex: 1;
  border: 0;
  margin: 0;
  padding: 0;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 24px;
  cursor: pointer;
  width: 100%;
}

.deliveryOverviewBoxIcon {
  font-size: 32px;
}

.deliveryOverviewBoxNumber {
  @extend .headline-medium;
  color: var(--md-sys-color-on-surface);
}

.deliveryOverviewBoxTitle {
  @extend .body-large;
  color: var(--md-sys-color-on-surface);
}

.overviewPageEventList {
  grid-area: events;
}

.overviewPageEventTitle {
  @extend .title-medium;
  width: 100%;
}

.overviewPageCardTitle {
  font-weight: lighter;
}

.overviewPageEventList {
  margin-bottom: 16px;
  padding: 16px;
  overflow-y: hidden;
  display: flex;
  flex-wrap: wrap;
}

.overviewPageEventListInner {
  max-height: 75vh;
  margin-right: -16px;
  padding-right: 16px;
  width: calc(100% + 16px);
  overflow-y: auto;
  margin-top: 16px;
  height: 100%;
}

.overviewPageEvent {
  transition: margin 0s !important;
  padding: 16px !important;
  display: grid !important;
  grid-template-columns: auto 1fr auto;
  width: 100%;
  align-items: center;
  grid-template-areas:
    'eventIcon recipientName date'
    // 'eventIcon type date';
    'eventIcon type date'
    'pausedChip pausedChip pausedChip';
}

.overviewPageEvent + .overviewPageEvent {
  margin-top: 16px;
}

.eventIcon {
  grid-area: eventIcon;
  padding-right: 8px;
}

.eventRecipient {
  grid-area: recipientName;
  text-overflow: ellipsis;
  overflow-x: hidden;
  @extend .title-small;
  white-space: nowrap;
  color: var(--md-sys-color-on-surface);
}

.eventType {
  grid-area: type;
  @extend .body-small;
  color: var(--md-sys-color-on-surface);
}

.eventDate {
  padding-left: 8px;
  grid-area: date;
  @extend .body-medium;
  color: var(--md-sys-color-on-surface);
}

.pausedChip {
  margin-top: 8px;
  grid-area: pausedChip;
}

.overviewPageAttentionList {
  grid-area: attention;
  overflow: hidden;

  & > div:last-child {
    height: 100%;
    overflow-y: auto;
  }
}

.overviewPageAttentionListTable {
  thead {
    th {
      border-bottom-color: var(--md-sys-color-surface-variant);
    }
  }
}

.overviewPageAttentionTitle {
  padding: 32px 24px;
  @extend .title-medium;
  width: 100%;
}

.emptyUpcomingEvents {
  @extend .body-medium;
  text-align: center;
  display: block;
  margin-top: 32px;
}