@import '../../../../node_modules/@chocolate-soup-inc/cs-frontend-components/lib/scss/typography.scss';

.withAddressAlert {
  background-color: var(--md-sys-color-tertiary-container);
  margin: 0px 24px 24px;
  border-radius: 8px;
}

.withAddressAlertContent {
  padding: 16px 36px 36px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.title {
  @extend .headline-small;
  // max-width: 50%;
}

.supportText {
  @extend .body-medium;
  // max-width: 50%;
}

.icon {
  color: var(--md-sys-color-error);
  font-size: 32px;
}

.rowNameColumn {
  display: flex;
  align-items: center;
  gap: 16px;
}