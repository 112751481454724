.statusContainer {
  display: flex;
  align-items: center;
  gap: 16px;
}

.statusIndicator {
  width: 7px;
  height: 7px;
  border-radius: 100%;
}