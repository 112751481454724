.sidebar {
  height: 100%;
  & > div:last-child {
    opacity: 0.4;
  }
}

.disabled {
  color: var(--md-sys-color-on-surface) !important;
  pointer-events: none;
}