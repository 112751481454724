@import '../../../../node_modules/@chocolate-soup-inc/cs-frontend-components/lib/scss/typography.scss';

.deliveryOverviewBoxIcon {
  font-size: 24px;
}

.passwordRequirement{
  @extend .display-small;
  display: flex;
  gap: 4px;
  margin: 4px 0;
  flex-direction: row;
  align-items: center;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
}

.valid {
  color: #00605E;
}

.invalid {
  color: #900000;
}