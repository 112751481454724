@import '../../../../node_modules/@chocolate-soup-inc/cs-frontend-components/lib/scss/typography.scss';

.employeeDependants {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.dependantTopComponent {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto;
  gap: 16px;
  margin-bottom: 16px;

  h1 {
    @extend .title-large;
  }
}

.errorChip {
  --md-sys-color-secondary-container: var(--md-sys-color-error);
  --md-sys-color-on-secondary-container: var(--md-sys-color-on-error);
  --md-sys-color-on-surface: var(--md-sys-color-on-error);
}