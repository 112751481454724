@import '../../../../node_modules/@chocolate-soup-inc/cs-frontend-components/lib/scss/typography.scss';

.topBar {
  margin: 16px 0;
}

.card {
  margin-top: 32px;
  display: block;
}

.addNew {
  display: block;
  margin-bottom: 24px;
}

.title {
  @extend .title-large;
  margin-bottom: 24px;
}