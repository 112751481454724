.filters {
  position: sticky;
  z-index: 2;
  display: flex;
  align-items: center;
  padding: 0 32px 32px;
  gap: 16px;
}

.filter {
  flex-grow: 1;
  max-width: 30%;
}