@import '../../../../node_modules/@chocolate-soup-inc/cs-frontend-components/lib/scss/typography.scss';

.missingAddressAlert {
  background-color: var(--md-sys-color-error-container);
  margin: 0px 24px 24px;
  border-radius: 8px;
}

.missingAddressAlertContent {
  padding: 16px 36px 36px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.title {
  @extend .headline-small;
  // max-width: 50%;
}

.supportText {
  @extend .body-medium;
  // max-width: 50%;
}

.icon {
  color: var(--md-sys-color-error);
  font-size: 32px;
}

.rowNameColumn {
  display: flex;
  align-items: center;
  gap: 16px;

  & > span:first-child {
    display: list-item;
    list-style: circle;
    margin-left: 16px;
  }
}

.errorChip {
  --md-sys-color-secondary-container: var(--md-sys-color-error);
  --md-sys-color-on-secondary-container: var(--md-sys-color-on-error);
  --md-sys-color-on-surface: var(--md-sys-color-on-error);
}