@import '../../../node_modules/@chocolate-soup-inc/cs-frontend-components/lib/scss/typography.scss';

.header {
  @extend .title-medium;
  padding: 8px 12px;
  height: 100%;
  width: 100%;
  background: #00635B;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  // z-index: 999999999;
}

.headerButton {
  span {
    color: white !important;
  }
}