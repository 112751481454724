@import '../../../../node_modules/@chocolate-soup-inc/cs-frontend-components/lib/scss/typography.scss';

.authPage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.authPageContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: -64px;
  padding: 32px;
  min-width: 240px;
  max-width: 480px;
}

.logo {
  max-width: 90px;
}

.title {
  @extend .display-small;
  text-align: center;
}

.supportText {
  @extend .body-large;
  text-align: center;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.formButton {
  & > div {
    display: flex;
    align-items: center;
    position: relative;

    & > div:last-child {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 16px;
    }
    span {
      flex: 1;
    }
  }
}