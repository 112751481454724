@import '../../../../node_modules/@chocolate-soup-inc/cs-frontend-components/lib/scss/typography.scss';

.employeeAccount {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.employeeOptionsMenu {
  display: flex;
  justify-content: flex-end;
  margin: -12px 0 12px;
}

.topComponent {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

.topTitleContainer {
  flex: 1;
  display: flex;
  align-items: center;
}

.topTitle {
  @extend .title-large;
  margin-right: 12px;
  flex: 1;
}

.topIcons, .topTitleContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  // flex: 1;
}

.alertTitle {
  @extend .body-medium;
}

.alertComponent {
  // border-bottom: 1px solid var(--md-sys-color-outline);
  background-color: var(--md-sys-color-tertiary-container);
  padding: 12px 24px 12px;
  // border-left: 6px solid var(--md-sys-color-tertiary-container);
  border-radius: 12px;
}

.alertTopIcons {
  justify-content: flex-end;
  flex: 1
}