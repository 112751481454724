.wrapper {
  display: flex;
}

.employeeManagement {
  display: flex;
  flex-direction: column;
  gap: 0;
  flex: 1;
}

.rowNameColumn {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 600px;
}

.errorChip {
  --md-sys-color-secondary-container: var(--md-sys-color-error);
  --md-sys-color-on-secondary-container: var(--md-sys-color-on-error);
  --md-sys-color-on-surface: var(--md-sys-color-on-error);
}
